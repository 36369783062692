import assert from 'assert'
import {
	IAdditionalInformationDTO,
	IRequestIncidentFormDTO,
	IRequestConfigsDTO,
	IRequestConsentDTO,
	IRequestDTO,
	IRequestMessageDTO,
	IRequestNumericTransactionDTO,
	ICoApplicantDTO,
	IConcernedInfoDTO
} from '@services/dtos/requests.dto'
import {
	IAdditionalInformation,
	IApplicant,
	ICommentsAttachment,
	IRequestIncidentForm,
	IRequest,
	IRequestConfigs,
	IRequestConsent,
	IRequestMessage,
	IRequestNumericTransaction,
	IRequestPartials,
	ITimeline,
	IUser,
	RequestMetadata,
	RequestStatus,
	ICoApplicant,
	IConcernedInfo
} from '@services/types'
import {
	RequestCategoryEnum,
	RequestConsentSourceDict,
	RequestConsentSourceEnum,
	RequestConsentSourceReverseDict,
	RequestPartialsEnum,
	RequestStatusDict,
	RequestStatusEnum,
	RequestStatusReverseDict
} from '@services/constants'
import RequestsRepository from '@services/repositories/requests.repository'
import {
	Exclude,
	Expose,
	instanceToPlain,
	plainToInstance,
	Transform,
	Type
} from 'class-transformer'
import { Engine } from 'json-rules-engine'
import { Address } from '@services/models/addresses.model'
import { CustomDate } from '@services/models/shared.model'
import {
	ActivityLogItem,
	Applicant,
	TeamMember
} from '@services/models/_miscellaneous.model'
import { IActivityLogItemDTO } from '@services/dtos/_miscellaneous.dto'
import { Dictionary } from 'typings/shared'
import {
	ATTACHMENT_REQUEST_FIELDS_NAME,
	CATEGORIES,
	REQUEST_CASE_ORIGIN_CODE_WEB
} from '@services/constants'
import { ITimelineDTO } from '@services/dtos/timeline.dto'
import { Timeline } from '@services/models/timeline.model'
import { CommentsAttachment } from '@services/models/comments.model'
import { ICommentsAttachmentDTO } from '@services/dtos/comments.dto'
import { ORIGIN, VALUE_CHANGE } from '@components/ui/historyCard/timelineBlock'
import {
	AdministrativeRiviewInfo,
	AnimalInformation,
	ParkingPermitInformation,
	PesticideCertificates,
	PlantedTree,
	TankTruck,
	VehicleInformation
} from '@utils/request'
import { Invoice } from './invoice.model'
import { config } from 'process'
import moment from 'moment'

const DESCRIPTION_SEPARATOR = '-'

// TODO: Make sure to wipe portalmetada and propose new design for that value... :(
// TODO: delete this after we've new metadata

// TODO: Implement to handle Q&A (Questions & Answers)
export class RequestQuestionTranslator {
	private requestType: string

	constructor(requestType: string) {
		this.requestType = requestType
	}

	public translate(assets: Dictionary, questionName: string) {
		try {
			const strapiKey = `request_form_${this.requestType}_${questionName}_label`

			return assets[strapiKey] ?? ''
		} catch (error) {
			console.error('Error, while translation question', error)
			return ''
		}
	}
}

export class additionalInformation implements IAdditionalInformation {
	@Expose({ name: 'unread_emails' })
	unreadEmail?: number

	@Exclude()
	public parse(dto: IAdditionalInformationDTO) {
		Object.assign(this, plainToInstance(additionalInformation, dto))
	}

	constructor(dto) {
		this.parse(dto)
	}
}

export class Request implements IRequest {
	@Exclude()
	private _baseURL = '/auth/requests/'

	@Expose({ name: 'incident_id' })
	id?: string | null

	@Expose({ name: 'case_origin_code' })
	caseOrigin: number = REQUEST_CASE_ORIGIN_CODE_WEB

	@Expose({ name: 'customer_id' })
	customerId?: string

	@Exclude()
	category?: RequestCategoryEnum

	@Expose({ name: 'location' })
	@Transform(({ value }) => new Address(value), { toClassOnly: true })
	@Transform(({ value }) => Address.serialize(value), {
		toPlainOnly: true
	})
	location: Address | null = null

	@Expose({ name: 'configs' })
	@Transform(({ value }) => new RequestConfigs(value), { toClassOnly: true })
	@Transform(({ value }) => RequestConfigs.serialize(value), {
		toPlainOnly: true
	})
	configs: RequestConfigs

	@Exclude()
	author: IUser

	@Expose({ name: 'overview' })
	@Transform(
		({ value }) => {
			return value?.replace("(poteaux d'incendie)", '')
		},
		{ toPlainOnly: true }
	)
	title: string = ''

	@Expose({ name: 'description' })
	description: string = ''

	@Expose({ name: 'incident_type_id' })
	typeId: string = ''

	@Expose({ name: 'incident_form_id' })
	formId?: string | null

	@Expose({ name: 'incident_status_id' })
	incidentStatusId?: string

	@Expose({ name: 'incident_sub_status_id' })
	incidentSubStatusId?: string

	@Expose({ name: 'name' })
	name?: string | null

	@Expose({ name: 'created_on' })
	@Transform(({ value }) => new CustomDate(value), { toClassOnly: true })
	dateAdd?: CustomDate

	@Expose({ name: 'modified_on' })
	@Transform(({ value }) => new CustomDate(value), { toClassOnly: true })
	dateUpdate?: CustomDate

	@Expose({ name: 'ticket_number' })
	code: string = ''

	@Expose({ name: 'action_failure_time' })
	actionFailureTime?: CustomDate

	@Expose({ name: 'resolution_failure_time' })
	resolutionFailureTime?: CustomDate

	@Expose({ name: 'action_days' })
	actionDays?: number

	@Expose({ name: 'resolution_days' })
	resolutionDays?: number

	@Expose({ name: 'status_code_portal' })
	@Transform(
		({ value }) => RequestStatusDict[value] ?? RequestStatusEnum.OPEN,
		{ toClassOnly: true }
	)
	@Transform(({ value }) => RequestStatusReverseDict[value] ?? 0, {
		toPlainOnly: true
	})
	status: RequestStatus = RequestStatusEnum.OPEN

	@Exclude({ toPlainOnly: true })
	@Expose({ name: 'status_code_portal' })
	subStatus: string = ''

	@Exclude()
	files?: File[]

	@Expose({ name: 'portal_metadata' })
	@Transform(({ value }) => JSON.parse(value) || ([] as RequestMetadata[]), {
		toClassOnly: true
	})
	metadata: RequestMetadata[] = []

	@Expose({ name: 'cancel_reason' })
	cancelReason?: number

	@Exclude({ toPlainOnly: true })
	@Expose({ name: 'additional_informations' })
	@Type(() => additionalInformation)
	additionalInformation?: IAdditionalInformation

	@Exclude()
	// TODO: to implement = history
	activities: ActivityLogItem[] = []

	@Exclude()
	emails: IRequestMessage[] = []

	@Exclude()
	comments: IRequestMessage[] = []

	@Exclude()
	consents: RequestConsent[] = []

	@Exclude()
	timeline: ITimeline[] = []

	@Exclude()
	attachments: ICommentsAttachment[] = []

	@Exclude()
	numericTransactions: RequestNumericTransaction[] = []

	@Expose({ name: 'incident_form', toClassOnly: true })
	@Type(() => RequestIncidentForm)
	incidentForm?: IRequestIncidentForm

	@Expose({ name: 'invoice' })
	@Type(() => Invoice)
	invoice?: Invoice

	@Exclude()
	private _engine: Engine

	@Exclude()
	private _repository: RequestsRepository

	@Exclude()
	private _isInstanciated: boolean = false

	constructor(author: IUser, dto?: IRequestDTO) {
		if (dto?.customer_id) {
			assert(
				dto.customer_id === author.profile?.id,
				'This request does not belong to the provided user'
			)
		}

		this._engine = new Engine()
		this._repository = new RequestsRepository()
		this.configs = new RequestConfigs()

		// TODO: add the rule to the request from the `request factory`
		if (dto) {
			this.parse(dto)
			this._isInstanciated = true
		}

		this.author = author
	}

	@Exclude()
	get link(): string {
		return this._baseURL + this.id ?? '#'
	}

	@Exclude()
	get otherComments(): string {
		try {
			const chunks: string[] = this.description?.split(DESCRIPTION_SEPARATOR)

			return chunks[chunks.length - 1].trim()
		} catch (error) {
			return ''
		}
	}

	@Exclude()
	public async saveAsync(cancelRequest?: boolean): Promise<string> {
		assert(
			this.author?.profile?.id,
			'Can not save a request without a valid author'
		)

		const dto = Request.serialize(this)

		assert(!!dto, 'Can not save empty request')

		if (this.id) {
			const id = await this._repository.patchRequest(dto, cancelRequest)

			return id
		}

		this.id = await this._repository.postRequest(dto)
		return this.id
	}

	@Exclude()
	public async saveAsyncTN(cancelRequest?: boolean): Promise<string> {
		assert(
			this.author?.profile?.id,
			'Can not save a request without a valid author'
		)

		const {
			configs,
			name,
			form_type_id,
			portal_metadata,
			ticket_number,
			...others
		} = Request.serialize(this)
		assert(!!others, 'Can not save empty request')

		const firstCoApplicant: ICoApplicantDTO = {
			first_name: configs?.first_owners_first_name!,
			last_name: configs?.first_owners_last_name!,
			telephone: configs?.first_owners_phone_number!,
			email: configs?.first_owners_email!
		}
		const secondCoApplicant: ICoApplicantDTO = {
			first_name: configs?.second_owners_first_name!,
			last_name: configs?.second_owners_last_name!,
			telephone: configs?.second_owners_phone_number!,
			email: configs?.second_owners_email!
		}

		const concernedInfo: IConcernedInfoDTO = {
			first_name: configs?.concerned_first_name!,
			last_name: configs?.concerned_last_name!,
			organization_name: configs?.concerned_organization_name!,
			telephone: configs?.concerned_telephone!,
			email: configs?.concerned_email!,
			address: configs?.concerned_address!,
			appartment: configs?.concerned_appartment!,
			city: configs?.concerned_city!,
			postal_code: configs?.concerned_postal_code!,
			state: configs?.concerned_state!,
			country: configs?.concerned_country!
		}

		const permitHolder: IConcernedInfoDTO = {
			first_name: configs?.concerned_first_name!,
			last_name: configs?.concerned_last_name!,
			organization_name: configs?.concerned_organization_name!,
			telephone: configs?.concerned_telephone!,
			email: configs?.concerned_email!,
			address: configs?.concerned_address!,
			appartment: configs?.concerned_appartment!,
			city: configs?.concerned_city!,
			postal_code: configs?.concerned_postal_code!,
			state: configs?.concerned_state!,
			country: configs?.concerned_country!
		}

		const buildingOwner: IConcernedInfoDTO = {
			first_name: configs?.concerned_first_name!,
			last_name: configs?.concerned_last_name!,
			organization_name: configs?.concerned_organization_name!,
			telephone: configs?.concerned_telephone!,
			email: configs?.concerned_email!,
			address: configs?.concerned_address!,
			appartment: configs?.concerned_appartment!,
			city: configs?.concerned_city!,
			postal_code: configs?.concerned_postal_code!,
			state: configs?.concerned_state!,
			country: configs?.concerned_country!
		}

		const buildingAddressObj = configs?.building_address ?? {
			address: configs?.concerned_address!,
			appartment: configs?.concerned_appartment!
		}

		const business: IConcernedInfoDTO = {
			first_name: '',
			last_name: configs?.business_name! ?? configs?.concerned_last_name,
			organization_name:
				configs?.business_name! ?? configs?.concerned_last_name,
			telephone: configs?.business_telephone! ?? configs?.concerned_telephone,
			email: configs?.business_email! ?? configs?.concerned_email,
			address: configs?.business_address! ?? configs?.concerned_address,
			appartment:
				configs?.business_appartment! ?? configs?.concerned_appartment,
			city: configs?.business_city! ?? configs?.concerned_city,
			postal_code:
				configs?.business_postal_code! ?? configs?.concerned_postal_code,
			state: configs?.business_state! ?? configs?.concerned_state,
			country: configs?.business_country! ?? configs?.concerned_country
		}

		const configurations = this.getDTOWithNoAttachmentData(
			configs as IRequestConfigsDTO
		)

		const incident_form = {
			...configurations,
			applicant: {
				first_name: this.author.profile?.firstName,
				last_name: this.author.profile?.lastName,
				address: this.author.profile?.address.address,
				postal_code: this.author.profile?.address.postalCode || 'none',
				city: this.author.profile?.address.city,
				signature_acceptance: true,
				signature_date: CustomDate.currentDate(),
				telephone:
					this.author.profile?.phoneNumber ||
					this.author.profile?.phoneNumberEvening ||
					this.author.profile?.phoneNumberOther,
				telephone_evening: this.author.profile?.phoneNumberEvening,
				telephone_other: this.author.profile?.phoneNumberOther,
				email: this.author.profile?.email,
				appartment: this.author.profile?.address.apartment
			},
			co_applicants: [firstCoApplicant, secondCoApplicant],
			concerned_info: concernedInfo,
			permit_holder: permitHolder || {},
			business: business || {},
			building_owner: buildingOwner || {},
			building_address: buildingAddressObj || {}
		}

		const dto = { ...others, incident_form }
		if (this.id) {
			const id = await this._repository.patchRequest(
				dto as IRequestDTO,
				cancelRequest
			)

			return id
		}

		this.id = await this._repository.postRequest(dto as IRequestDTO)
		return this.id
	}

	@Exclude()
	public async saveConfig(resource: string) {
		assert(!!this.configs, 'Can not save empty configs')
		assert(resource, 'URL is required to save the request')

		const { configs, incident_id, form_type_id } = Request.serialize(this)
		const firstCoApplicant: ICoApplicantDTO = {
			first_name: configs?.first_owners_first_name!,
			last_name: configs?.first_owners_last_name!,
			telephone: configs?.first_owners_phone_number!,
			email: configs?.first_owners_email!
		}
		const secondCoApplicant: ICoApplicantDTO = {
			first_name: configs?.second_owners_first_name!,
			last_name: configs?.second_owners_last_name!,
			telephone: configs?.second_owners_phone_number!,
			email: configs?.second_owners_email!
		}
		const concernedInfo: IConcernedInfoDTO = {
			first_name: configs?.concerned_first_name!,
			last_name: configs?.concerned_last_name!,
			organization_name: configs?.concerned_organization_name!,
			telephone: configs?.concerned_telephone!,
			email: configs?.concerned_email!,
			address: configs?.concerned_address!,
			appartment: configs?.concerned_appartment!,
			city: configs?.concerned_city!,
			postal_code: configs?.concerned_postal_code!,
			state: configs?.concerned_state!,
			country: configs?.concerned_country!
		}

		const buildingOwner: IConcernedInfoDTO = {
			first_name: configs?.concerned_first_name!,
			last_name: configs?.concerned_last_name!,
			organization_name: configs?.concerned_organization_name!,
			telephone: configs?.concerned_telephone!,
			email: configs?.concerned_email!,
			address: configs?.concerned_address!,
			appartment: configs?.concerned_appartment!,
			city: configs?.concerned_city!,
			postal_code: configs?.concerned_postal_code!,
			state: configs?.concerned_state!,
			country: configs?.concerned_country!
		}

		// const buildingAddress = {
		// 	address: configs?.concerned_address!,
		// 	appartment: configs?.concerned_appartment!
		// }

		const permitHolder: IConcernedInfoDTO = {
			first_name: configs?.concerned_first_name!,
			last_name: configs?.concerned_last_name!,
			organization_name: configs?.concerned_organization_name!,
			telephone: configs?.concerned_telephone!,
			email: configs?.concerned_email!,
			address: configs?.concerned_address!,
			appartment: configs?.concerned_appartment!,
			city: configs?.concerned_city!,
			postal_code: configs?.concerned_postal_code!,
			state: configs?.concerned_state!,
			country: configs?.concerned_country!
		}

		const business: IConcernedInfoDTO = {
			first_name: '',
			last_name: configs?.business_name! ?? configs?.concerned_last_name,
			organization_name:
				configs?.business_name! ?? configs?.concerned_last_name,
			telephone: configs?.business_telephone! ?? configs?.concerned_telephone,
			email: configs?.business_email! ?? configs?.concerned_email,
			address: configs?.business_address! ?? configs?.concerned_address,
			appartment:
				configs?.business_appartment! ?? configs?.concerned_appartment,
			city: configs?.business_city! ?? configs?.concerned_city,
			postal_code:
				configs?.business_postal_code! ?? configs?.concerned_postal_code,
			state: configs?.business_state! ?? configs?.concerned_state,
			country: configs?.business_country! ?? configs?.concerned_country
		}

		const configurations = this.getDTOWithNoAttachmentData(
			configs as IRequestConfigsDTO
		)
		const dto = {
			...configurations,
			incident_id,
			form_type_id,
			applicant: {
				first_name: this.author.profile?.firstName,
				last_name: this.author.profile?.lastName,
				address: this.author.profile?.address.address,
				postal_code: this.author.profile?.address.postalCode || 'none',
				city: this.author.profile?.address.city,
				signature_acceptance: true,
				signature_date: CustomDate.currentDate(),
				telephone:
					this.author.profile?.phoneNumber ||
					this.author.profile?.phoneNumberEvening ||
					this.author.profile?.phoneNumberOther,
				email: this.author.profile?.email,
				appartment: this.author.profile?.address.apartment
			},
			co_applicants: [firstCoApplicant, secondCoApplicant],
			concerned_info: concernedInfo,
			permit_holder: permitHolder || {},
			business: business || {},
			building_owner: buildingOwner || {}
			// building_address: buildingAddress || {}
		}

		this.formId = await this._repository.postTNConfigs(
			dto as IRequestDTO,
			resource
		)
	}

	@Exclude()
	public async saveAttachmentAsync() {
		assert(
			this.id,
			'Can not save an attachment if the request was not created before'
		)

		assert(this.files, 'At least a file is needed')

		for (const file of this.files) {
			const attachmentData = new FormData()

			attachmentData.append(ATTACHMENT_REQUEST_FIELDS_NAME.file, file)
			attachmentData.append(
				ATTACHMENT_REQUEST_FIELDS_NAME.incidentId,
				`${this.id}`
			)

			await this._repository.postAttachment(attachmentData)
		}
	}

	@Exclude()
	public async addConsent(consent: RequestConsent): Promise<string> {
		// TODO: add asserts later
		// assert(
		// 	this.author?.profile?.id,
		// 	'Can not save a request without a valid author'
		// )

		const dto = RequestConsent.serialize(consent)

		assert(!!dto, 'Can not save empty request')

		// TODO: update later
		// this.consents.push()

		return await this._repository.postConsent(dto)
	}

	@Exclude()
	public async revokeConsent(consent: RequestConsent): Promise<void> {
		// TODO: add asserts later
		assert(consent.id, 'Can not revoke a consent without a valid id')

		const dto = RequestConsent.serialize(consent)

		assert(!!dto, 'Can not save empty request')

		await this._repository.deleteConsent(consent.id)

		this.consents = this.consents.filter(({ id }) => id !== consent.id)
	}

	@Exclude()
	public update(request: Partial<IRequest>) {
		Object.assign(this, request)
	}

	@Exclude()
	public parse(dto: Partial<IRequestDTO>) {
		Object.assign(this, plainToInstance(Request, dto))
	}

	// this is mainly used for the page: request details of a user
	@Exclude()
	static async build(requestId: string, author: IUser): Promise<Request> {
		// fetch from the API using the id from the repo and construct the request
		const repository = new RequestsRepository()
		const dto = await repository.getRequest(requestId)

		return new Request(author, dto)
	}

	@Exclude()
	static serialize(request: Request): IRequestDTO {
		const {
			portal_metadata: metadataOut,
			author: authorOut,
			files: filesOut,
			activities: activitiesOut,
			messages: messagesOut,
			...rest
		} = instanceToPlain(request)

		const dto = rest as IRequestDTO

		dto.customer_id = request.author?.profile?.id ?? ''
		dto.created_on = request.dateAdd?.UTCDateString
		dto.modified_on = request.dateUpdate?.UTCDateString
		dto.portal_metadata = JSON.stringify(request.metadata)

		if (!request.id) {
			delete dto.status_code_portal
		}

		return dto
	}

	// TODO: do the same for the DTO, and the form
	@Exclude()
	isValid = async (): Promise<boolean> => {
		// use json-rules-engine and class-validator
		return false
	}

	// @TODO: @Bill NAME has changed to LABEL which is a better name
	// Let you modify the getQnA function...
	@Exclude()
	// questions and answers of the request
	public getQnA(
		pageAssets: Dictionary,
		requestStrapiKey: string,
		excludedLabel?: string,
		requestList?: any
	): { question: string; answer: string }[] {
		const qTranslator = new RequestQuestionTranslator(requestStrapiKey)

		return this.metadata
			?.filter((meta) => meta.label !== excludedLabel)
			?.map(({ label, value }: RequestMetadata) => {
				if (value.match(/^\w+(-\w+){2,}$/gi)) {
					const request = requestList.find((r) => r.requestId == value)
					return {
						question: pageAssets[label],
						answer: request
							? request?.title +
							  (request?.description ? ` (${request?.description})` : '')
							: value
					}
				}

				const selectedRequest = requestList.find((request) =>
					request.name.includes(value)
				)
				if (selectedRequest) {
					return selectedRequest.title
				}

				return {
					question: pageAssets[label],
					answer: pageAssets[value] ?? value.replace(/\n/g, '<br>')
				}
			})
			?.filter((el) => !!el.question)
	}

	public getTNQuestions(
		pageAssets: Dictionary,
		requestName: string,
		question: string
	) {
		return new RequestQuestionTranslator(requestName).translate(
			pageAssets,
			question
		)
	}

	/** @deprecated */
	@Exclude()
	static async updatePartials(request: Request): Promise<void> {
		assert(
			request.id,
			'The id field of the Request is mandatory to fetch its partials'
		)

		// TODO: fetch history: [GET] no endpoint :(
		const activitiesDTO: IActivityLogItemDTO[] =
			(await request._repository.getRequestPartialsAsync(
				request.id,
				RequestPartialsEnum.HISTORY
			)) as IActivityLogItemDTO[]
		const emailsDTO: IRequestMessageDTO[] =
			(await request._repository.getRequestPartialsAsync(
				request.id,
				RequestPartialsEnum.EMAILS
			)) as IRequestMessageDTO[]
		const commentsDTO: IRequestMessageDTO[] =
			(await request._repository.getRequestPartialsAsync(
				request.id,
				RequestPartialsEnum.COMMENTS
			)) as IRequestMessageDTO[]
		const consentsDTO: IRequestConsentDTO[] =
			(await request._repository.getRequestPartialsAsync(
				request.id,
				RequestPartialsEnum.CONSENTS
			)) as IRequestConsentDTO[]

		// convert dtos to methods
		request.emails = emailsDTO.map((dto) => new RequestMessage(dto))
		request.comments = commentsDTO.map((dto) => new RequestMessage(dto))
		request.consents = consentsDTO.map(
			(dto) => new RequestConsent(request.author, dto)
		)
	}

	@Exclude()
	static async getPartial(
		request: Request,
		partial: RequestPartialsEnum
	): Promise<void> {
		assert(
			request.id,
			'The id field of the Request is mandatory to fetch its partials'
		)

		switch (partial) {
			case RequestPartialsEnum.EMAILS:
				const emailsDTO: IRequestMessageDTO[] =
					(await request._repository.getRequestPartialsAsync(
						request.id,
						RequestPartialsEnum.EMAILS
					)) as IRequestMessageDTO[]

				// convert dtos to methods
				request.emails = emailsDTO.map((dto) => new RequestMessage(dto))
				break
			case RequestPartialsEnum.COMMENTS:
				const commentsDTO: IRequestMessageDTO[] =
					(await request._repository.getRequestPartialsAsync(
						request.id,
						RequestPartialsEnum.COMMENTS
					)) as IRequestMessageDTO[]

				// convert dtos to methods
				request.comments = commentsDTO.map((dto) => new RequestMessage(dto))
				break
			case RequestPartialsEnum.CONSENTS:
				const consentsDTO: IRequestConsentDTO[] =
					(await request._repository.getRequestPartialsAsync(
						request.id,
						RequestPartialsEnum.CONSENTS
					)) as IRequestConsentDTO[]

				// convert dtos to methods
				request.consents = consentsDTO.map(
					(dto) => new RequestConsent(request.author, dto)
				)
				break
			default:
				break
		}
	}

	/**
	 * Summary: Fetches all the partials of a request and update the request accordingly.
	 *
	 * Description: Fetches all the partials of a request and update the request accordingly.
	 *
	 * @since      0.4.9                  replaces static method `updatePartials`
	 * @access     public
	 *
	 * @return {Promise<Map<RequestPartialsEnum, IRequestPartials>>}   Promise of list of Request partials.
	 */
	@Exclude()
	// TODO: assign values to object instead of returning them
	async fetchPartialsAsync(): Promise<
		Map<RequestPartialsEnum, IRequestPartials>
	> {
		assert(
			this.id,
			// build error class (codes, text, strapiAssetKey)
			'The id field of the Request is mandatory to fetch its partials'
		)

		const requestPartials = new Map<RequestPartialsEnum, IRequestPartials>()

		// TODO: Use RequestHistory
		// const activitiesDTO: IActivityLogItemDTO[] =
		// 	(await this._repository.getRequestPartialsAsync(
		// 		this.id,
		// 		RequestPartialsEnum.HISTORY
		// 	)) as IActivityLogItemDTO[]
		const emailsDTO: IRequestMessageDTO[] =
			(await this._repository.getRequestPartialsAsync(
				this.id,
				RequestPartialsEnum.EMAILS
			)) as IRequestMessageDTO[]
		const commentsDTO: IRequestMessageDTO[] =
			(await this._repository.getRequestPartialsAsync(
				this.id,
				RequestPartialsEnum.COMMENTS
			)) as IRequestMessageDTO[]
		const consentsDTO: IRequestConsentDTO[] =
			(await this._repository.getRequestPartialsAsync(
				this.id,
				RequestPartialsEnum.CONSENTS
			)) as IRequestConsentDTO[]
		const timelineDTO: ITimelineDTO[] = (await Timeline.buildAsync(
			this.id
		)) as ITimelineDTO[]
		const attachmentsDTO: ICommentsAttachmentDTO[] =
			await this._repository.getAttachments(this.id)
		const attachmentsFile: ICommentsAttachment[] = []
		const numericTransactionsDTO: IRequestNumericTransactionDTO[] =
			await this._repository.getNumericTransactions(this.id)

		for (let attachment of attachmentsDTO) {
			if (attachment) {
				attachmentsFile.push(
					new CommentsAttachment(
						await this._repository.getAttachmentsFiles(attachment.id)
					)
				)
			}
		}

		// convert dtos to methods
		this.emails = emailsDTO.map((dto) => new RequestMessage(dto))
		this.comments = commentsDTO.map((dto) => new RequestMessage(dto))
		this.numericTransactions = numericTransactionsDTO.map(
			(dto) => new RequestNumericTransaction(dto)
		)
		this.consents = consentsDTO.map(
			(dto) => new RequestConsent(this.author, dto)
		)
		this.timeline = timelineDTO.map((dto) => new Timeline(dto))

		for (let timeline of this.timeline) {
			if (timeline.activity?.category === CATEGORIES.CANCEL_REQUEST) {
				const cTimeline = this.timeline.find(
					(timeline) => timeline.valueChange?.type === VALUE_CHANGE.CANCELATION
				)
				if (cTimeline) {
					cTimeline.activity = timeline.activity
				}
			}

			if (
				timeline.from == ORIGIN.CITY &&
				timeline.activity?.overview?.includes('création d’une requête')
			) {
				const creationElement = this.timeline?.find(
					(element) => element.valueChange?.valueLabel == VALUE_CHANGE.CREATION
				)

				if (creationElement) {
					creationElement.activity = timeline.activity
				}
			}
		}

		for (let tn of this.numericTransactions) {
			for (let attachment of tn.attachments || []) {
				attachment.fileContent = new CommentsAttachment(
					await this._repository.getAttachmentsFiles(attachment.id)
				).fileContent
			}
		}

		this.attachments = attachmentsFile

		requestPartials.set(RequestPartialsEnum.EMAILS, this.emails)
		requestPartials.set(RequestPartialsEnum.COMMENTS, this.comments)
		requestPartials.set(RequestPartialsEnum.CONSENTS, this.consents)
		requestPartials.set(RequestPartialsEnum.CONSENTS, this.timeline)
		requestPartials.set(
			RequestPartialsEnum.NUMERIC_TRANSACTION,
			this.numericTransactions
		)

		return requestPartials
	}

	private getDTOWithNoAttachmentData(dto: IRequestConfigsDTO) {
		// Removing the attachment(content) data from the payload
		let fieldInputs = {}
		Object.keys(dto).forEach((key) => {
			if (key.includes('Input')) {
				fieldInputs[key] = dto[key]
			}
		})

		Object.keys(fieldInputs).forEach((key) => {
			if (fieldInputs[key]['files']) {
				delete fieldInputs[key]['files']
			} else {
				Object.keys(fieldInputs[key]).forEach((key2) => {
					if (key2.includes('#')) {
						delete fieldInputs[key][key2]['content']
					}
				})
			}
		})

		return {
			...dto,
			...fieldInputs
		}
	}
}

export class RequestConfigs implements IRequestConfigs {
	@Expose({ name: 'property_type' })
	propertyType?: number

	@Expose({ name: 'equipment_type' })
	equipmentType?: number

	@Expose({ name: 'on_the_waterside' })
	waterside?: number

	@Expose({ name: 'dangerous' })
	dangerousTree?: number

	@Expose({ name: 'tax_information' })
	taxInformation?: number

	@Expose({ name: 'speed_signaling_intervention' })
	intervantionType?: number

	@Expose({ name: 'program_list' })
	programList?: number

	@Expose({ name: 'nature_gathering' })
	natureCollecting?: number

	@Expose({ name: 'collection_location' })
	collectionLocation?: number

	@Expose({ name: 'collection_sector' })
	collectionSector?: number

	@Expose({ name: 'lodging_sector' })
	collectionHousing?: number

	@Expose({ name: 'container_provided_by_city' })
	containerProvided?: number

	@Expose({ name: 'immediate_attention' })
	immediateAttention?: boolean

	@Expose({ name: 'emergency_type' })
	emergencyType?: number

	@Expose({ name: 'new_bin' })
	newBin?: number

	@Expose({ name: 'container_collection' })
	containerCollection?: number

	@Expose({ name: 'number_on_bin' })
	numberOnBin?: string

	@Expose({ name: 'snow_removal_by' })
	@Transform((object) => parseInt(object.value), { toPlainOnly: true })
	snowRemovalBy?: number

	@Expose({ name: 'place_type_snow_removal' })
	placeTypeSnowRemoval?: number

	@Expose({ name: 'injury_occurred' })
	@Transform((object) => parseInt(object.value), { toPlainOnly: true })
	injuryOccurred?: number

	@Expose({ name: 'fire_hydrant_snow_removal' })
	@Transform((object) => parseInt(object.value), { toPlainOnly: true })
	fireHydrantSowRemoval?: number

	@Expose({ name: 'request_type' })
	requestType?: string

	@Expose({ name: 'covered_sewer_intervention' })
	coveredSewerIntervention?: number

	@Expose({ name: 'place_type' })
	placeType?: number

	@Expose({ name: 'location_place' })
	locationPlace?: number

	@Expose({ name: 'unclean_origin' })
	uncleanOrigin?: number

	@Expose({ name: 'lighting_intervention' })
	lightingIntervention?: number

	@Expose({ name: 'sector_off' })
	sectorOff?: number

	@Expose({ name: 'location_lighting' })
	locationLighting?: number

	@Expose({ name: 'signaling_intervention' })
	signalingIntervention?: number

	@Expose({ name: 'signaling_type' })
	signalingType?: number

	@Expose({ name: 'dropped_stop_sign' })
	droppedStopSign?: number

	@Expose({ name: 'harsh_signage' })
	harshSignage?: number

	@Expose({ name: 'required_files' })
	requiredFiles?: File[]

	@Expose({ name: 'subject' })
	subject?: number

	@Expose({ name: 'road_users_security' })
	roadUsersSecurity?: number

	// FORM TN fields

	@Expose({ name: 'beneficiary_category' })
	grantForWhom?: number

	@Expose({ name: 'beneficiary_situation' })
	situationString?: string

	@Expose({ name: 'beneficiary_firstname' })
	beneficiaryFirstName?: string

	@Expose({ name: 'beneficiary_lastname' })
	beneficiaryLastName?: string

	@Expose({ name: 'beneficiary_address' })
	beneficiaryAddress?: string

	@Expose({ name: 'beneficiary_appartment' })
	beneficiaryAppartment?: string

	@Expose({ name: 'child_firstname_1' })
	dependentFirstChildFirstName?: string

	@Expose({ name: 'child_lastname_1' })
	dependentFirstChildLastName?: string

	@Expose({ name: 'child_firstname_2' })
	dependentSecondChildFirstName?: string

	@Expose({ name: 'child_lastname_2' })
	dependentSecondChildLastName?: string

	@Expose({ name: 'child_firstname_3' })
	dependentThirdChildFirstName?: string

	@Expose({ name: 'child_lastname_3' })
	dependentThirdChildLastName?: string

	@Expose({ name: 'hygienic_product_category' })
	buyingProducts?: number

	@Expose({ name: 'purchase_amount_before_taxes' })
	totalAmount?: string

	@Expose({ name: 'subsidy_received' })
	subsidyReceived?: boolean

	@Expose({ name: 'subscription_date' })
	subscriptionDate?: string

	@Expose({ name: 'fees_amount' })
	subscriptionAmount?: string

	@Expose({ name: 'child_firstname' })
	beneficiaryChildFirstName?: string

	@Expose({ name: 'child_lastname' })
	beneficiaryChildLastName?: string

	@Expose({ name: 'previous_subscription' })
	previousSubscription?: boolean

	@Expose({ name: 'subscription_type' })
	subscriptionType?: number

	/////////

	@Expose({ name: 'subvention_chosen_option' })
	subventionChosen?: number

	@Expose({ name: 'child_first_name' })
	childFirstName?: string

	@Expose({ name: 'child_last_name' })
	childLastName?: string

	@Expose({ name: 'child_birth_date' })
	childBirthDate?: string

	@Expose({ name: 'adult_residant_count' })
	adultResidentNumber?: number

	@Expose({ name: 'children_residant_count' })
	childrenResidentNumber?: number

	@Expose({ name: 'manufacturer_name1' })
	manufacturerName1?: string

	@Expose({ name: 'cost_before_tax1' })
	costBeforeTax1?: number

	@Expose({ name: 'name_and_model_number1' })
	nameAndModelNumber1?: string

	@Expose({ name: 'manufacturer_name2' })
	manufacturerName2?: string

	@Expose({ name: 'cost_before_tax2' })
	costBeforeTax2?: number

	@Expose({ name: 'name_and_model_number2' })
	nameAndModelNumber2?: string

	@Expose({ name: 'manufacturer_name3' })
	manufacturerName3?: string

	@Expose({ name: 'cost_before_tax3' })
	costBeforeTax3?: number

	@Expose({ name: 'name_and_model_number3' })
	nameAndModelNumber3?: string

	@Expose({ name: 'accomodation_type' })
	accommodationType?: number

	@Expose({ name: 'number_of_accomodation_converted' })
	numberOfAccommodationConverted?: number

	@Expose({ name: 'converted_accomodation_list' })
	convertedAccommodationList?: string

	@Expose({ name: 'fuel_system_replaced_type' })
	fuelSystemReplacedType?: string

	@Expose({ name: 'heating_replacement_system_type' })
	heatingReplacementSystemType?: string

	@Expose({ name: 'contrator_licence_number' })
	contactorLicenseNumber?: string

	@Expose({ name: 'subvention_total_value_requested' })
	subventionTotalValueRequested?: number

	@Expose({ name: 'building_address_same_has_applicant' })
	buildingAddressSameHasApplicant?: boolean

	@Expose({ name: 'building_address' })
	buildingAddress?: string | IConcernedInfo

	@Expose({ name: 'building_appartment' })
	buildingApartment?: string

	@Expose({ name: 'building_postal_code' })
	buildingPostalCode?: string

	@Expose({ name: 'building_city' })
	buildingCity?: string

	@Expose({ name: 'first_owners_last_name' })
	firstOwnersLastName?: string

	@Expose({ name: 'first_owners_first_name' })
	firstOwnersFirstName?: string

	@Expose({ name: 'first_owners_email' })
	firstOwnersEmail?: string

	@Expose({ name: 'first_owners_phone_number' })
	firstOwnersPhoneNumber?: string

	@Expose({ name: 'second_owners_last_name' })
	secondOwnersLastName?: string

	@Expose({ name: 'second_owners_first_name' })
	secondOwnersFirstName?: string

	@Expose({ name: 'second_owners_email' })
	secondOwnersEmail?: string

	@Expose({ name: 'second_owners_phone_number' })
	secondOwnersPhoneNumber?: string

	@Expose({ name: 'building_type' })
	buildingType?: number

	@Expose({ name: 'building_category' })
	buildingCategory?: number

	@Expose({ name: 'number_of_accomodation' })
	buildingNumber?: number

	@Expose({ name: 'construction_state' })
	workProgress?: number

	@Expose({ name: 'construction_date' })
	workDate?: string

	@Expose({ name: 'construction_permit_number' })
	permitNumber?: string

	@Expose({ name: 'building_cadastral_number' })
	buildingCadastralNumber?: string

	@Expose({ name: 'co_applicants' })
	coApplicants?: ICoApplicant[]

	@Expose({ name: 'concerned_info' })
	concernedInfo?: IConcernedInfo

	@Expose({ name: 'trees' })
	plantedTreeList?: PlantedTree[]

	@Expose({ name: 'is_over_year_owner' })
	isOverYearOwner?: boolean

	@Expose({ name: 'inscription_date' })
	notarialRegistrationDate?: string

	@Expose({ name: 'inscription_number' })
	notarialRegistrationNumber?: string

	@Expose({ name: 'claimant_different_from_applicant' })
	isConcernedDiffFromApplicant?: boolean

	@Expose({ name: 'claim_nature' })
	claimNature?: number

	@Expose({ name: 'damaged_material' })
	materialDamage?: boolean

	@Expose({ name: 'city_collection_bin_only_damage' })
	isDamageLimitedToBin?: boolean

	@Expose({ name: 'owner_of_damaged_vehicle' })
	isOwnerOfDamagedVehicle?: boolean

	@Expose({ name: 'owner_full_name' })
	actualOwnerOfDamagedVehicle?: string

	@Expose({ name: 'owner_or_tenant' })
	isOwnerOrRenter?: number

	@Expose({ name: 'vehicle' })
	vehicleInformation?: VehicleInformation

	@Expose({ name: 'event_location' })
	incidentLocation?: string

	@Expose({ name: 'event_date' })
	incidentDate?: string

	@Expose({ name: 'event_time' })
	incidentTime?: string

	@Expose({ name: 'damage_discovery_date' })
	damageDiscoveryDate?: string

	@Expose({ name: 'police_report_number' })
	policeReportNumber?: string

	@Expose({ name: '311_request_number' })
	request311Number?: string

	@Expose({ name: 'event_description' })
	damageCauseDescription?: string

	@Expose({ name: 'damage_description' })
	damageDescription?: string

	@Expose({ name: 'concerned_last_name' })
	concernedLastName?: string

	@Expose({ name: 'concerned_first_name' })
	concernedFirstName?: string

	@Expose({ name: 'concerned_address' })
	concernedAdresse?: string

	@Expose({ name: 'concerned_appartment' })
	concernedAppartement?: string

	@Expose({ name: 'concerned_email' })
	concernedEmail?: string

	@Expose({ name: 'concerned_telephone' })
	concernedPhoneNumber?: string

	@Expose({ name: 'concerned_city' })
	concernedCity?: string

	@Expose({ name: 'concerned_state' })
	concernedProvince?: string

	@Expose({ name: 'concerned_postal_code' })
	concernedPostalCode?: string

	@Expose({ name: 'concerned_country' })
	concernedCountry?: string

	@Expose({ name: 'billing_same_has_applicant' })
	isSameBillingAdresse?: boolean

	@Expose({ name: 'request_reason' })
	lotRequestReason?: number

	@Expose({ name: 'owner_same_has_applicant' })
	isApplicantLotOwner?: boolean

	@Expose({ name: 'relation_to_owner' })
	relationToOwner?: string

	@Expose({ name: 'property_lot_numbers' })
	lotNumbersList?: string[]

	@Expose({ name: 'property_matricule' })
	registrationNumber?: string

	@Expose({ name: 'property_surface' })
	landAreaDimensions?: number

	@Expose({ name: 'property_surface_measure_unit' })
	landAreaMeasurements?: number

	@Expose({ name: 'request_description' })
	requestObject?: string

	@Expose({ name: 'business_same_as_personal' })
	businessSameAsPersonal?: boolean

	@Expose({ name: 'building_batch_number' })
	buildingBatchNumber?: string

	@Expose({ name: 'building_registration_number' })
	buildingRegistrationNumber?: string

	@Expose({ name: 'building_is_owner' })
	isBuildingOwner?: boolean

	@Expose({ name: 'building_owners_name' })
	ownerFullName?: string

	@Expose({ name: 'is_replaced_system' })
	isDeclarationForHeatingReplacement?: boolean

	@Expose({ name: 'replacement_date' })
	installationDate?: string

	@Expose({ name: 'have_fuel_heating_system' })
	isOilHeatingSystem?: boolean

	@Expose({ name: 'is_dual_energy' })
	isDualEnergySystem?: boolean

	@Expose({ name: 'replaced_to_system' })
	convertedHeatingSystemType?: number

	@Expose({ name: 'main_system_energy_source' })
	heatingEnergySource?: number

	@Expose({ name: 'main_system_heating_type' })
	mainHeatingSystemType?: number

	@Expose({ name: 'main_system_install_year' })
	approximateInstallYear?: number

	@Expose({ name: 'on_behalf_of_organization' })
	isOrganizationRequest?: boolean

	@Expose({ name: 'organization_name' })
	organizationName?: string

	@Expose({ name: 'applicant_function_in_organization' })
	applicantPurpose?: string

	@Expose({ name: 'document_specification' })
	specifiedDocuments?: string

	@Expose({ name: 'research_subject' })
	researchSubject?: string

	@Expose({ name: 'concerned_sector' })
	concernedSector?: string

	@Expose({ name: 'research_period_start_date' })
	coveredSearchPeriodStart?: string

	@Expose({ name: 'research_period_end_date' })
	coveredSearchPeriodEnd?: string

	@Expose({ name: 'is_specific_technicality' })
	requiresTechnicalSpecs?: boolean

	@Expose({ name: 'technical_specification' })
	technicalSpecs?: string

	@Expose({ name: 'on_site_consultation' })
	isInPersonAppointment?: boolean

	@Expose({ name: 'appointment_date_time' })
	appointmentDate?: string

	@Expose({ name: 'appointment_time' })
	appointmentTime?: string

	@Expose({ name: 'appointment_notes' })
	appointmentComment?: string

	@Expose({ name: 'is_broadcasting_archive' })
	areDocumentsDiffused?: boolean

	@Expose({ name: 'licencing_to' })
	isLicenceRecipientApplicant?: number

	// @Expose({ name: 'type_of_use' })
	// useTypeString?: string

	@Expose({ name: 'use_type' })
	useType?: string

	@Expose({ name: 'projet_title' })
	projectTitle?: string

	@Expose({ name: 'projet_description' })
	projectDescription?: string

	@Expose({ name: 'publishing_house' })
	publishingHouse?: string

	@Expose({ name: 'release_date' })
	releaseDate?: string

	// Autorelève des compteurs d'eau
	@Expose({ name: 'situation_applies' })
	ownerWaterCounterStatus?: string

	@Expose({ name: 'declare_address' })
	declareAddress?: string

	@Expose({ name: 'declare_appartment' })
	declareAppartment?: string

	@Expose({ name: 'reading_date' })
	counterReadingDate?: string

	@Expose({ name: 'reading_value' })
	counterReadingValue?: string

	@Expose({ name: 'serial_number' })
	counterSerialNumber?: string

	@Expose({ name: 'occupant_count' })
	occupantNumber?: number

	@Expose({ name: 'email_notification' })
	registerForEmail?: boolean

	@Expose({ name: 'is_contrat_with_city' })
	isContractWithCity?: boolean

	@Expose({ name: 'contrat_number' })
	contractNumber?: string

	@Expose({ name: 'city_contact_name' })
	cityRespondentName?: string

	@Expose({ name: 'desired_testing_date' })
	desiredDateForHydraulicTest?: string

	@Expose({ name: 'temporary_installation_related' })
	isRelatedToInstallingTemporaryNetwork?: boolean

	@Expose({ name: 'hydraulic_tests' })
	@Transform(({ value }) => (value ? JSON.parse(value) : undefined), {
		toPlainOnly: true
	})
	hydraulicTestList?: string[]

	@Expose({ name: 'property_address_same_as_applicant' })
	@Transform((object) => object.value == 'true', { toPlainOnly: true })
	propertyAddressSameAsApplicant?: boolean

	@Expose({ name: 'property_address' })
	propertyAddress?: string

	@Expose({ name: 'property_postal_code' })
	propertyPostalCode?: string

	@Expose({ name: 'property_ex_city' })
	propertyExCity?: string

	@Expose({ name: 'property_lot_number' })
	propertyLotNumber?: string

	@Expose({ name: 'property_is_owner' })
	@Transform((object) => object.value == 'true', { toPlainOnly: true })
	propertyIsOwner?: boolean

	@Expose({ name: 'contractor_company_name' })
	contractorCompanyName?: string

	@Expose({ name: 'contractor_permis_number' })
	contractorPermisNumber?: string

	@Expose({ name: 'contractor_telephone' })
	contractorTelephone?: string

	@Expose({ name: 'type_of_infestation' })
	typeOfInfestation?: string

	@Expose({ name: 'type_of_infestation_other' })
	typeOfInfestationOther?: string

	@Expose({ name: 'infestation_location' })
	locationOfInfestation?: string

	@Expose({ name: 'treating_area' })
	spaceToBeTreated?: string

	@Expose({ name: 'treating_area_other' })
	spaceToBeTreatedOther?: string

	@Expose({ name: 'treating_surface' })
	@Transform((object) => parseInt(object.value), { toPlainOnly: true })
	areaToBeTreated?: string

	@Expose({ name: 'planned_application_date' })
	expectedDateOfApplication?: string

	@Expose({ name: 'history_problem_description' })
	historyOfTheProblem?: string

	@Expose({ name: 'control_method_applied' })
	controlMethodsAlreadyApplied?: string

	@Expose({ name: 'control_method_applied_specify' })
	specifyControlMethodsApplied?: string

	@Expose({ name: 'trade_product_name' })
	productTradeName?: string

	@Expose({ name: 'certification_number' })
	certificationNumber?: string

	@Expose({ name: 'active_ingredient' })
	activeIngredient?: string

	@Expose({ name: 'business_name' })
	companyName?: string

	@Expose({ name: 'business_quebec_number' })
	quebecBusinessNumber?: string

	@Expose({ name: 'business_agent_name' })
	representativeName?: string

	@Expose({ name: 'business_agent_title' })
	representativeTitle?: string

	@Expose({ name: 'business_address' })
	companyAdresse?: string
	@Expose({ name: 'business_appartment' })
	companyAppartement?: string
	@Expose({ name: 'business_city' })
	companyCity?: string
	@Expose({ name: 'business_state' })
	companyProvince?: string
	@Expose({ name: 'business_postal_code' })
	companyPostalCode?: string
	@Expose({ name: 'business_country' })
	companyCountry?: string
	@Expose({ name: 'business_email' })
	companyEmail?: string
	@Expose({ name: 'business_telephone' })
	companyPhoneNumber?: string

	@Expose({ name: 'business' })
	business?: IConcernedInfo

	@Expose({ name: 'holder_is_same_as_business' })
	isSameInformations?: boolean

	@Expose({ name: 'permit_holder' })
	permitHolder?: IConcernedInfo

	@Expose({ name: 'permit_number' })
	permitLicenseNumber?: string

	@Expose({ name: 'permit_expiration_date' })
	permitExpiryDate?: string

	@Expose({ name: 'vehicles' })
	vehicleInformationList?: VehicleInformation[]

	@Expose({ name: 'pesticide_certificats' })
	pesticideCertificatesList?: PesticideCertificates[]

	@Expose({ name: 'start_date' })
	startDate?: string

	@Expose({ name: 'end_date' })
	endDate?: string

	@Expose({ name: 'fire_hydrant_location' })
	whichFireHydrantToUse?: string

	@Expose({ name: 'contrator_name' })
	contractorInChargeOfWorks?: string

	@Expose({ name: 'city_submission_number' })
	citySubmissionNumber?: string

	@Expose({ name: 'temporary_network_firm' })
	firmInChargeOfTemporaryNetwork?: string

	@Expose({ name: 'will_use_as_indicated' })
	isCertified?: boolean

	@Expose({ name: 'sector_fire_hydrant_location' })
	whichFireHydrantsToUseList?: string

	@Expose({ name: 'type_of_use' })
	typeOfUse?: string

	@Expose({ name: 'intended_type_of_use' })
	intendedWaterUsage?: string

	// annual permit and tag for animal
	@Expose({ name: 'guardian_age_range' })
	yearsOlder?: number

	@Expose({ name: 'emergency_contact_last_name' })
	otherGuardName?: string

	@Expose({ name: 'emergency_contact_first_name' })
	otherGuardFirstName?: string

	@Expose({ name: 'emergency_contact_telephone' })
	otherGuardPhoneNumber?: string

	@Expose({ name: 'emergency_contact_other_tel' })
	otherGuardOtherPhoneNumber?: string

	@Expose({ name: 'emergency_contact_link' })
	relationshipWithGuard?: string

	@Expose({ name: 'requested_action' })
	whatToDo?: number

	@Expose({ name: 'animals' })
	animalInformationList?: AnimalInformation[]

	@Expose({ name: 'covered_by_exception' })
	coveredByException?: boolean

	@Expose({ name: 'special_permit_term' })
	specialPermit?: string

	@Expose({ name: 'eligibility_clause_1' })
	eligibilityClause1?: boolean

	@Expose({ name: 'eligibility_clause_2' })
	eligibilityClause2?: boolean

	@Expose({ name: 'eligibility_clause_3' })
	eligibilityClause3?: boolean

	@Expose({ name: 'eligibility_clause_4' })
	eligibilityClause4?: boolean

	@Expose({ name: 'eligibility_clause_5' })
	eligibilityClause5?: boolean

	// fireplace declaration
	@Expose({ name: 'building_owner' })
	buildingOwner?: IConcernedInfo

	@Expose({ name: 'heating_source' })
	heatingSource?: string

	@Expose({ name: 'heating_source_other' })
	otherHeatingSource?: string

	@Expose({ name: 'heating_source_proportion' })
	firstHeatingSourcePercentage?: string

	@Expose({ name: 'heating_source_other_proportion' })
	secondHeatingSourcePercentage?: string

	@Expose({ name: 'declaration' })
	declarationType?: string

	@Expose({ name: 'type_of_heater' })
	fireplaceTypeToDeclare?: string

	@Expose({ name: 'type_of_heater_other' })
	otherFireplaceTypeToDeclare?: string

	@Expose({ name: 'heater_usage_frequency' })
	frequencyOfUse?: string

	@Expose({ name: 'year_of_installation' })
	installationYear?: string

	@Expose({ name: 'heater_floor_location' })
	deviceLocation?: string

	@Expose({ name: 'heater_floor_location_other' })
	otherDeviceLocation?: string

	@Expose({ name: 'heater_manufacturer' })
	deviceManifacturer?: string

	@Expose({ name: 'heater_model_number' })
	deviceModel?: string

	@Expose({ name: 'is_heater_certified' })
	isDeviceCertified?: string

	@Expose({ name: 'heater_certification' })
	deviceCertifications?: string

	@Expose({ name: 'particle_emission_rate' })
	deviceParticleEmissionRate?: string

	@Expose({ name: 'masonry_contractor_name' })
	deviceContractorName?: string

	@Expose({ name: 'heater_replaced_by' })
	deviceReplacementType?: string

	@Expose({ name: 'heater_replace_withdrawal_date' })
	deviceWithdrawalDate?: string

	// @Expose({ name: 'vehicles' })
	// tankTruckList?: TankTruck[]

	// Municipal evaluation
	@Expose({ name: 'research_type' })
	addressOption?: string

	@Expose({ name: 'unit_appartment' })
	addressAppartment?: string

	@Expose({ name: 'unit_address' })
	addressField?: string

	@Expose({ name: 'unit_cadastre' })
	addressRegistrationNumber?: string

	@Expose({ name: 'unit_lot_number' })
	addressLotNumber?: number

	@Expose({ name: 'applicant_is' })
	applicantStatus?: string

	@Expose({ name: 'applicant_precision' })
	provideMoreInfo?: string

	@Expose({ name: 'financial_exercise' })
	evaluationRole?: string

	@Expose({ name: 'request_origin_is' })
	requestOrigin?: string

	@Expose({ name: 'change_notice_number' })
	roleModification?: string

	@Expose({ name: 'official_correction_notice_number' })
	roleCorrection?: string

	@Expose({ name: 'building_value_is' })
	@Transform(
		({ value }) => {
			if (typeof value === 'string') {
				const data = value?.split(';')[0]
				return data?.includes('x')
			}
			return false
		},
		{ toPlainOnly: true }
	)
	omission?: string

	@Expose({ name: 'real_value_according_to_applicant' })
	askerValue?: string

	@Expose({ name: 'other_registration_is' })
	@Transform(
		({ value }) => {
			if (typeof value === 'string') {
				const data = value?.split(';')[1]
				return data?.includes('x')
			}
			return false
		},
		{ toPlainOnly: true }
	)
	omissionOther?: string

	@Expose({ name: 'nature_of_registration' })
	otherInscription?: string

	@Expose({ name: 'building_value' })
	buildingValueToRole?: string

	@Expose({ name: 'reasons_supporting_the_request' })
	requestSupportInfo?: string

	// permit for occupation of highway
	@Expose({ name: 'type_of_request' })
	isNewRequest?: string

	@Expose({ name: 'requesting_as' })
	makingRequestAs?: string

	@Expose({ name: 'hired_a_company' })
	haveContractedCompany?: string

	@Expose({ name: 'agreement_with_city' })
	haveAgreementWithCity?: string

	@Expose({ name: 'connection_permit_obtained' })
	workFollowInsurance?: string

	@Expose({ name: 'connection_permit_number' })
	connectionPermitNumber?: string

	@Expose({ name: 'connection_inspector_name' })
	nameOfLavalCity?: string

	@Expose({ name: 'in_charge_of_project' })
	personInchargeOfProject?: string

	@Expose({ name: 'company_in_charge_info' })
	companyInChargeInfo?: IConcernedInfo

	@Expose({ name: 'in_charge_supervisor' })
	isSupervisor?: string

	@Expose({ name: 'supervisor_info' })
	supervisorInfo?: IConcernedInfo

	@Expose({ name: 'nature_of_occupancy' })
	natureOfIntervention?: string

	@Expose({ name: 'nature_of_occupancy_other' })
	natureOfInterventionOther?: string

	@Expose({ name: 'nature_of_occupancy_additional_info' })
	additionalInformation?: string

	@Expose({ name: 'work_address' })
	mainAddressOfWork?: string

	@Expose({ name: 'start_of_obstacle' })
	startOfTheDisruption?: string

	@Expose({ name: 'end_of_obstacle' })
	endOfTheDisruption?: string

	@Expose({ name: 'complete_street_closure' })
	isFullOrPatialClosure?: string

	@Expose({ name: 'parking_occupancy_only' })
	willRoadBeLimited?: string

	@Expose({ name: 'impact_on_traffic' })
	impactsOnTraffic?: string

	@Expose({ name: 'width_of_obstacle' })
	approximateWidthOfObstruction?: string

	@Expose({ name: 'length_of_obstable' })
	approximateLengthOfObstruction?: string

	@Expose({ name: 'MTMD_standard_using' })
	useStandardizedBoard?: string

	@Expose({ name: 'MTMD_drawing_number' })
	numberOfStandardizedDrawing?: string

	@Expose({ name: 'near_trafiic_lights_intersection' })
	isLocatedNearIntersection?: string

	@Expose({ name: 'allowing_emergency_vehicle' })
	willAllowPassage?: string

	@Expose({ name: 'blocking_bus_stop' })
	willMakeAnSTL?: string

	@Expose({ name: 'lowering_of_speed_limit' })
	isATemporaryReduction?: string

	@Expose({ name: 'occupancy_location_addtional_info' })
	anyAdditionalInformation?: string

	@Expose({ name: 'company_responsible_of_signaling' })
	companyResponsibleForSignage?: string

	@Expose({ name: 'signaling_responsible_company' })
	signalingResponsibleCompany?: IConcernedInfo

	@Expose({ name: 'occupancy_period' })
	occupationDate?: string

	@Expose({ name: 'continu_start_date' })
	occupationStartDate?: string

	@Expose({ name: 'continu_end_date' })
	occupationEndDate?: string

	@Expose({ name: 'continu_nb_days' })
	occupationNbOfDays?: number

	@Expose({ name: 'punctual_dates' })
	dateTimeList?: AdministrativeRiviewInfo[]

	@Expose({ name: 'occupancy_period_additonal_info' })
	additionalOccupationInformation?: string

	@Expose({ name: 'disruption_period_additional_info' })
	additionalDisruptionInformation?: string

	@Expose({ name: 'occupancy_respecting_exigences' })
	occupancyRespectingExigences?: string

	@Expose({ name: 'request_number' })
	requestNumber?: string

	@Expose({ name: 'request_number_othere' })
	requestNumberOther?: string

	@Expose({ name: 'calender_date' })
	calendarDate?: string

	// Parking sticker
	@Expose({ name: 'same_address_as_applicant' })
	sameAddressAsApplicant?: boolean

	@Expose({ name: 'demand_address' })
	demandAddress?: string

	@Expose({ name: 'demand_appartment' })
	demandAppartment?: string

	@Expose({ name: 'transaction_reason' })
	transactionReason?: string

	@Expose({ name: 'replacing_reason' })
	replacingReason?: string

	@Expose({ name: 'replacing_reason_other' })
	replacingReasonOther?: string

	@Expose({ name: 'parking_stickers' })
	parkingPermitList?: ParkingPermitInformation[]

	// Fireplace grant
	@Expose({ name: 'old_device_brand' })
	oldDeviceMake?: string

	@Expose({ name: 'old_device_model' })
	oldDeviceModel?: string

	@Expose({ name: 'new_device_brand' })
	newDeviceMake?: string

	@Expose({ name: 'new_device_model' })
	newDeviceModel?: string

	@Expose({ name: 'new_device_total_cost' })
	installationCosts?: number

	@Expose({ name: 'is_declaration_done' })
	declarationExistsOnAddress?: boolean

	@Expose({ name: 'is_subvention_already_granted' })
	subventionExistsOnAddress?: boolean

	// swimmingPoolSpaPermit

	@Expose({ name: 'request_as' })
	requestAs?: number

	@Expose({ name: 'is_property_owner' })
	isLocationOwned?: boolean

	@Expose({ name: 'building_owner_info' })
	buildingOwnerInfo?: IConcernedInfo

	@Expose({ name: 'is_joint_ownership' })
	isPropertyJointOwnership?: boolean

	@Expose({ name: 'is_located_on_waterfront' })
	isPropertyLocatedOnWaterfront?: boolean

	@Expose({ name: 'installation_type' })
	installationType?: number

	@Expose({ name: 'is_2000_liter_hot_tub' })
	isSpaLitersCondition?: boolean

	@Expose({ name: 'shape_of_pool' })
	poolShape?: number

	@Expose({ name: 'dimesion_diameter' })
	poolDiameter?: number
	@Expose({ name: 'dimesion_diameter_unit' })
	poolDiameterUnit?: number

	@Expose({ name: 'dimesion_height' })
	poolHeight?: number
	@Expose({ name: 'dimesion_height_unit' })
	poolHeightUnit?: number

	@Expose({ name: 'dimesion_width' })
	poolWidth?: number
	@Expose({ name: 'dimesion_width_unit' })
	poolWidthUnit?: number

	@Expose({ name: 'dimesion_length' })
	poolLength?: number
	@Expose({ name: 'dimesion_length_unit' })
	poolLengthUnit?: number

	@Expose({ name: 'installation_date' })
	expectedInstallDate?: string

	@Expose({ name: 'cost_of_installation' })
	installationCost?: number

	@Expose({ name: 'is_tree_cut_required' })
	isTreeCutRequired?: boolean

	@Expose({ name: 'pool_seller_info' })
	poolSellerInfo?: IConcernedInfo

	@Expose({ name: 'protective_enclosure_type' })
	protectiveEnclosureType?: number

	@Expose({ name: 'protective_enclosure_type_date' })
	protectiveEnclosurePurchaseDate?: string

	@Expose({ name: 'protective_enclosure_has_door' })
	isProtectiveEnclosureHasWindows?: string


	// Building construction or addition permit
	@Expose({ name: 'is_work_sujected_to_piia' })
	isSubjectToPiiaApproval?: boolean

	@Expose({ name: 'is_subjected_to_piia' })
	hasBeenSubjectToApproval?: boolean

	@Expose({ name: 'ia_number' })
	iaNumber?: string

	@Expose({ name: 'resolution_number' })
	ecResolutionNumber?: string

	@Expose({ name: 'is_real_estate_project' })
	isRealEstateProject?: boolean

	@Expose({ name: 'pd_number' })
	projectNumber?: string

	@Expose({ name: 'building_main_use' })
	mainUseOfNewBuilding?: string

	@Expose({ name: 'new_building_type' })
	isTheNewBuilding?: string

	@Expose({ name: 'is_building_coowned' })
	isTheNewBuildingPartOfAProperty?: boolean

	@Expose({ name: 'structure_type' })
	structureType?: string

	@Expose({ name: 'building_floor_area' })
	buildingArea?: number

	@Expose({ name: 'building_floor_area_unit' })
	buildingAreaUnit?: number

	@Expose({ name: 'total_floor_area' })
	totalGrossFloorArea?: number

	@Expose({ name: 'total_floor_area_unit' })
	totalGrossFloorAreaUnit?: number

	@Expose({ name: 'is_for_habitation' })
	isDeclaredAreaForResidentialUse?: boolean

	@Expose({ name: 'non_residential_areas' })
	nonResidentialAreas?: number

	@Expose({ name: 'non_residential_areas_unit' })
	nonResidentialAreasUnit?: number

	@Expose({ name: 'number_of_floors' })
	numberOfFloors?: number

	@Expose({ name: 'number_of_dwellings' })
	numberOfResidentialUnits?: number

	@Expose({ name: 'building_has_garage' })
	hasGarage?: boolean

	@Expose({ name: 'garage_location' })
	garageLocation?: string

	@Expose({ name: 'number_of_outdoor_space' })
	nbrOfParkingSpacesExt?: number

	@Expose({ name: 'number_of_indoor_space' })
	nbrOfParkingSpacesInt?: number

	@Expose({ name: 'number_of_visitor_space' })
	nbrOfParkingSpacesVis?: number

	@Expose({ name: 'number_of_bicycles_units' })
	nbrOfBikeSpaces?: number

	@Expose({ name: 'follows_disaster' })
	isWorkDueToDisaster?: boolean

	@Expose({ name: 'follows_disaster_other' })
	workDueToDisasterPrecision?: string

	@Expose({ name: 'cost_of_work' })
	approximateCostOfWork?: number

	@Expose({ name: 'work_total_value' })
	totalProjectValue?: number

	@Expose({ name: 'require_tree_felling' })
	isTreeCuttingRequired?: boolean

	@Expose({ name: 'additional_information' })
	additionalInfoQ28?: string

	@Expose({ name: 'work_start_date' })
	workStartDate?: string

	@Expose({ name: 'work_end_date' })
	workEndDate?: string

	@Expose({ name: 'type_of_contractor' })
	whoWillBeInChargeOfWork?: string

	@Expose({ name: 'company_name' })
	companyNameExecutingWork?: string

	@Expose({ name: 'company_number' })
	companyNEQNumber?: string

	@Expose({ name: 'company_licence_number' })
	companyRBQNumber?: string

	@Expose({ name: 'representative_info' })
	representativeInfo?: IConcernedInfo

	@Expose({ name: 'contact_details_info' })
	contactDetailsInfo?: IConcernedInfo

	@Expose({ name: 'lot_connected_to_pipe' })
	isPropertyConnectedToMunicipalConduits?: string

	@Expose({ name: 'main_pipes_precision' })
	additionalInfoQ42?: string

	@Expose({ name: 'construction_type' })
	plannedWork?: string

	@Expose({ name: 'construction_precision' })
	additionalInfoQ44?: string

	@Expose({ name: 'contractor_unknown' })
	entrepreneurUnknown?: boolean

	@Expose({ name: 'company_name2' })
	entrepreneurName?: string

	// @Expose({ name: 'company_number2' })
	// entrepreneurRBQNumber?: string

	@Expose({ name: 'company_licence_number2' })
	entrepreneurRBQNumber?: string

	@Expose({ name: 'representative_info2' })
	representativeInfo2?: IConcernedInfo

	@Expose({ name: 'contact_details_info2' })
	contactDetailsInfo2?: IConcernedInfo

	@Expose({ name: 'existing_main_building_use' })
	mainUseOfExistingBuilding?: string

	@Expose({ name: 'existing_main_building_is_shared' })
	existingMainBuildingIsShared?: boolean

	@Expose({ name: 'added_building_type' })
	whatToBuildOrImprove?: string

	@Expose({ name: 'new_building_construction_type' })
	constructionType?: string

	@Expose({ name: 'new_building_type_precision' })
	constructionTypeOther?: string

	@Expose({ name: 'foundation_type' })
	foundationType?: string

	@Expose({ name: 'location_on_field' })
	placeOnTheTerrain?: string

	@Expose({ name: 'building_width' })
	buildingWidth?: number

	@Expose({ name: 'building_width_unit' })
	buildingWidthUnit?: number

	@Expose({ name: 'building_length' })
	buildingLength?: number

	@Expose({ name: 'building_length_unit' })
	buildingLengthUnit?: number

	@Expose({ name: 'building_height' })
	buildingHeight?: number

	@Expose({ name: 'building_height_unit' })
	buildingHeightUnit?: number

	@Expose({ name: 'building_area' })
	buildingSurfaceArea?: number

	@Expose({ name: 'building_area_unit' })
	buildingSurfaceAreaUnit?: number

	@Expose({ name: 'exterior_materials' })
	buildingExternalCoveringMaterials?: string

	@Expose({ name: 'roof_materials' })
	buildingRoofMaterials?: string

	@Expose({ name: 'structure_materials' })
	buildingStructureMaterials?: string

	@Expose({ name: 'unit_address2' })
	addressVoiePublic?: string

	@Expose({ name: 'request_address_civic_number2' })
	addressCivicNumber?: string

	@Expose({ name: 'unit_address3' })
	addressVoiePublic2?: string

	@Expose({ name: 'request_address_civic_number3' })
	addressCivicNumber2?: string

	@Expose({ name: 'unit_address4' })
	addressVoiePublic3?: string

	@Expose({ name: 'request_address_civic_number4' })
	addressCivicNumber3?: string

	@Expose({ name: 'additional_address' })
	addressAdditionalInfos?: string

	// -----------------------------------------------

	constructor(requestConfigDTO?: IRequestConfigsDTO) {
		if (requestConfigDTO) {
			this.parse(requestConfigDTO)
		}
	}

	public update(config: Partial<RequestConfigs>) {
		Object.assign(this, config)
	}

	public parse(requestConfigDTO: IRequestConfigsDTO) {
		Object.assign(this, plainToInstance(RequestConfigs, requestConfigDTO))
	}

	static serialize(requestConfigs: RequestConfigs): IRequestConfigsDTO {
		return instanceToPlain(requestConfigs) as IRequestConfigsDTO
	}
}

export class RequestMessage implements IRequestMessage {
	@Expose({ name: 'id' })
	id: string | null = null

	@Expose({ name: 'created_on' })
	@Transform(({ value }) => new CustomDate(value), { toClassOnly: true })
	dateAdd?: CustomDate

	@Expose({ name: 'description' })
	text: string = ''

	@Expose({ name: 'subject' })
	title: string = ''

	@Expose({ name: 'portal_message_read' })
	isRead: boolean = false

	@Expose({ name: 'incident_id' })
	incidentId: string = ''

	@Expose({ name: 'category' })
	category?: number

	@Exclude({ toPlainOnly: true })
	attachments?: ICommentsAttachment[] | undefined = []

	@Exclude()
	_hasBuilt: boolean = false

	constructor(requestMessageDTO?: IRequestMessageDTO) {
		if (requestMessageDTO) {
			this.parse(requestMessageDTO)
		}
	}

	parse = (requestMessageDTO: IRequestMessageDTO) => {
		Object.assign(this, plainToInstance(RequestMessage, requestMessageDTO))
	}

	static serialize(requestMessage: RequestMessage): IRequestMessageDTO {
		return instanceToPlain(requestMessage) as IRequestMessageDTO
	}
}

export class RequestConsent implements IRequestConsent {
	@Expose({ name: 'consent_id' })
	id: string | null = ''

	@Expose({ name: 'created_on' })
	@Exclude({ toPlainOnly: true })
	@Transform(({ value }) => new CustomDate(value), { toClassOnly: true })
	dateAdd: CustomDate | undefined

	@Expose({ name: 'revoked_on' })
	@Exclude({ toPlainOnly: true })
	@Transform(({ value }) => (value ? new CustomDate(value) : null), {
		toClassOnly: true
	})
	dateRevoked: CustomDate | undefined

	@Exclude()
	author: IUser

	@Expose({ name: 'is_followed' })
	isFollowed: boolean = false

	@Expose({ name: 'source' })
	@Transform(
		({ value }) =>
			(RequestConsentSourceDict[value] as RequestConsentSourceEnum) ??
			RequestConsentSourceEnum.MY_ACCOUNT
	)
	source: RequestConsentSourceEnum = RequestConsentSourceEnum.MY_ACCOUNT

	@Exclude()
	request?: Request

	@Expose({ name: 'elected_team_id' })
	@Transform(
		({ value }) =>
			TeamMember.getLocalElectedTeamMembers().find(
				(teamMember) => teamMember.id === value
			) || new TeamMember()
	)
	electedTeamMember: TeamMember | undefined

	@Exclude()
	public get isRevoked(): boolean {
		return !!this.dateRevoked?.UTCDateString
	}

	constructor(author: IUser, dto?: IRequestConsentDTO) {
		this.author = author

		if (dto) {
			this.parse(dto)
			const request: Request | undefined = author?.getRequest(
				dto.incident_id || ''
			)

			// TODO: To fix and uncomment
			// assert(
			// 	request,
			// 	'Can not instanciate a consent for a user with no valid request'
			// )

			this.request = request
		}
	}

	@Exclude()
	public parse(dto: IRequestConsentDTO) {
		Object.assign(this, plainToInstance(RequestConsent, dto))
	}

	@Exclude()
	static serialize(model: RequestConsent): IRequestConsentDTO {
		const dto = instanceToPlain(model) as IRequestConsentDTO

		if (!dto.consent_id) {
			delete dto.consent_id
		}

		dto.elected_team_id = model.electedTeamMember?.id
		dto.incident_id = model.request?.id ?? ''
		dto.customer_id = model.request?.author?.profile?.id ?? ''

		dto.created_on = model.dateAdd?.UTCDateString
		dto.revoked_on = model.dateRevoked?.UTCDateString
		dto.source = RequestConsentSourceReverseDict[model.source]

		return dto
	}
}

export class RequestNumericTransaction implements IRequestNumericTransaction {
	@Expose({ name: 'id' })
	id?: string

	@Expose({ name: 'incident_id' })
	incidentId: string = ''

	@Expose({ name: 'incident_form_id' })
	incidentFormId: string = ''

	@Expose({ name: 'subject' })
	subject: string = ''

	@Expose({ name: 'description' })
	description: string = ''

	@Expose({ name: 'category' })
	category?: RequestCategoryEnum

	@Exclude()
	file?: File

	@Expose({ name: 'attachments', toClassOnly: true })
	@Type(() => CommentsAttachment)
	attachments?: CommentsAttachment[]

	@Exclude()
	private _repository: RequestsRepository

	@Exclude()
	public parse(
		dto: IRequestNumericTransaction | IRequestNumericTransactionDTO
	) {
		Object.assign(this, plainToInstance(RequestNumericTransaction, dto))
	}

	@Exclude()
	static serialize(
		RequestNumericTransaction: IRequestNumericTransaction
	): IRequestNumericTransactionDTO {
		const dto = instanceToPlain(RequestNumericTransaction)

		return dto as IRequestNumericTransactionDTO
	}

	constructor(
		dto?: IRequestNumericTransactionDTO | IRequestNumericTransaction
	) {
		if (dto) {
			this.parse(dto)
		}

		this._repository = new RequestsRepository()
	}

	public async saveAsyncNumericTransaction() {
		assert(this, "Can't save numeric transactions with empty fields")

		const dto = RequestNumericTransaction.serialize(this)

		this.id = await this._repository.postNumericTransactions(dto)
	}

	public async saveTNAttachment() {
		assert(this.file, " Can't save empty files")

		const attachmentData = new FormData()

		attachmentData.append(ATTACHMENT_REQUEST_FIELDS_NAME.file, this.file)
		attachmentData.append(
			ATTACHMENT_REQUEST_FIELDS_NAME.numericTransactionId,
			`${this.id}`
		)

		await this._repository.postAttachment(attachmentData)
	}
}

export class RequestIncidentForm
	extends RequestConfigs
	implements IRequestIncidentForm
{
	@Expose({ name: 'applicant' })
	@Type(() => Applicant)
	applicant?: IApplicant

	@Expose({ name: 'incident_id' })
	incidentId: string = ''

	@Expose({ name: 'form_id' })
	formId: string = ''

	constructor(dto: IRequestIncidentFormDTO) {
		super(dto)
	}
}

export class RequestHistory {}
